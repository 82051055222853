import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll"; // Import the Link component


import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
     
        <p className="text-pink-600">Hi, I'm </p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Md MAHBUB E WADUZZAMAN
        </h1>
        <h2 className="text-2xl my-5 sm:text-5xl font-bold text-[#57e150]">
          <Typewriter
            options={{
              strings: ["  A Full Stack Developer.", "Front End Developer.", "Android App Developer.", "Cybersecurity Analyst.", "UX/UI Researcher.", "WordPress Developer.", "SEO Expert."],
              autoStart: true,
              loop: true,
            }}
          />
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          I’m a full-stack developer specializing in building and occasionally
          designing exceptional digital experiences. Currently, I’m focused on
          building responsive full-stack web applications.
        </p>
        <div>
          {/* Wrap the Link component properly */}
          <Link to="work" smooth={true} duration={500}>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600">
              View Work
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
