
import Travel from '../assets/projects/travel.png'
import Library from '../assets/projects/library.png'
import Drum from '../assets/projects/Drum.jpg'
import Weather from '../assets/projects/Weather.jpg'
import expense from '../assets/projects/expense.png'
import realestate from '../assets/projects/realestate.png'


export const data=[

    {
        id:1,
        name:"Library Management MERN Stack App",
        image:Library,
        github:"https://github.com/waduzzaman/library-management-client",
        live:"https://community-library-d20f8.web.app/",
    },
    {
        id:2,
        name:"Travel Management MERN Stack App",
        image:Travel,
        github:"https://github.com/waduzzaman/tourism-management-client",
        live:"https://top-tourism-management.netlify.app/",
    },
   
    {
        id:3,
        name:"Real Estate Managment MERN Stack Appp",
        image:realestate,
        github:"https://github.com/waduzzaman/real-estate-client",
        live:"https://real-estate-client-b69c6.firebaseapp.com//",
    },
    

    // {
    //     id:4,
    //     name:"HTML, CSS, JavaScript",
    //     image:Panda,
    //     github:"",
    //     live:"https://waduzzaman.github.io/panda-ecommerce/#",
    // },
    // {
    //     id:5,
    //     name:"HTML, CSS Application",
    //     image:Tech,
    //     github:"",
    //     live:"https://tech-edu-centre.netlify.app",
    // },
    {
        id:6,
        name:"Drum Kit using Vanilla JavaScript, CSS3 and HTML5 ",
        image:Drum,
        github:"https://github.com/waduzzaman/Drum-Kit",
        live:"https://waduzzaman.github.io/Drum-Kit/",
    },
 
    {
        id:7,
        name:"Weather App :ReactJS & OpenWeatherMap API",
        image:Weather,
        github:"https://github.com/waduzzaman/weather-app-reactjs/tree/main",
        live:"https://weather-app-reactjs-2xkowhgjh-waduzzaman.vercel.app/",
    },
    {
        id:8,
        name:"Expense Tracker App with NextJs and TailwindCSS",
        image:expense,
        github:"https://github.com/waduzzaman/expense-tracker",
        live:"https://expense-tracker-beige-theta.vercel.app/",
    },


]