import React from "react";
import {FaTwitter, FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa"; // Import React Icons

const Footer = () => {
  return (
    <div>
      <footer className="footer footer-center bg-base-200 text-base-content rounded p-10">
        <nav className="grid grid-flow-col gap-4">
          <a className="link link-hover">About Me</a>
          <a className="link link-hover">Contact</a>
          <a className="link link-hover">Works</a>
          <a
            className="flex justify-between items-center w-full text-gray-300"
            href="resume.pdf"
            download
          >
            Resume
          </a>
        </nav>
        <nav>
          <div className="grid grid-flow-col gap-4">
            <a href="https://x.com/waduzzaman" target="_blank" rel="noopener noreferrer">

              <FaTwitter size={24} className="fill-current" />
            </a>
            <a href="https://www.linkedin.com/in/waduzzaman/" target="_blank" rel="noopener noreferrer">
              <FaYoutube size={24} className="fill-current" />
            </a>
            <a href="https://www.facebook.com/mahbubwaduzzaman" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={24} className="fill-current" />
            </a>
            <a href="https://www.linkedin.com/in/waduzzaman/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={24} className="fill-current" />
            </a>
          </div>
        </nav>
        <aside>
          <p>
            Copyright © {new Date().getFullYear()} - All rights reserved by{" "}
            <a
              className="text-blue-400"
              href="https://personal-portfolio-reactjs-tailwind.vercel.app/"
            >
              Md Mahbub E Waduzzaman
            </a>
          </p>
        </aside>
      </footer>
    </div>
  );
};

export default Footer;
